export const FirebaseConfig = {
	"projectId": "calldrivers-app",
	"appId": "1:1057520968630:web:b8b7c11544cc99f7749717",
	"databaseURL": "https://calldrivers-app-default-rtdb.asia-southeast1.firebasedatabase.app",
	"storageBucket": "calldrivers-app.appspot.com",
	"locationId": "asia-southeast1",
	"apiKey": "AIzaSyDDmgOSwj5F5RHWEAyrZiyequXFp55HQAo",
	"authDomain": "calldrivers-app.firebaseapp.com",
	"messagingSenderId": "1057520968630",
	"measurementId": "G-X287G0Y2HM"
};